import React, { useEffect, useState } from "react";

import BookingDates from "../components/BookingDates";
import BookingDetails from "../components/BookingDetails";
import BookingForm from "./BookingForm";
import DeleteBooking from "./DeleteBooking";
import Modal from "./Modal";
import PropTypes from "prop-types";

function Booking(props) {
  const [deleteBookingModalOpen, setDeleteBookingModalOpen] = useState(false);
  const [updateBookingModalOpen, setUpdateBookingModalOpen] = useState(false);

  const deleteModalClassName = deleteBookingModalOpen
    ? "Modal--deleteBooking isActive"
    : "Modal--deleteBooking";

  const updateModalClassName = updateBookingModalOpen
    ? "Modal--bookingForm isActive"
    : "Modal--bookingForm";

  useEffect(() => {
    document.body.classList.remove("hasModal");
    setUpdateBookingModalOpen(false);
  }, [props.data]);

  return (
    <>
      <Modal heading="Delete booking" className={deleteModalClassName}>
        <DeleteBooking
          id={props.data.id}
          handleDeleteBooking={props.handleDeleteBooking}
          handleClose={() => {
            document.body.classList.remove("hasModal");
            setDeleteBookingModalOpen(false);
          }}
        />
      </Modal>
      <Modal heading="Update booking" className={updateModalClassName}>
        <BookingForm
          id={props.data.id}
          handleSubmit={props.handleSubmitBooking}
          bookedDates={props.bookedDates}
          data={props.data}
          modalOpen={updateBookingModalOpen}
          handleClose={() => {
            document.body.classList.remove("hasModal");
            setUpdateBookingModalOpen(false);
          }}
          hasError={props.formHasError}
        />
      </Modal>
      <div className="Booking" data-test-hook="booking">
        <div className="Booking-summary">
          <div className="Booking-dates">
            <BookingDates
              startDate={props.data.start_date}
              endDate={props.data.end_date}
            />
          </div>

          <div className="Booking-buttons">
            <div className="Booking-buttonContainer">
              <button
                className={`Booking-button${
                  props.updatingBooking ? " Booking-button--disabled" : ""
                }`}
                onClick={() => {
                  document.body.classList.add("hasModal");
                  setUpdateBookingModalOpen(true);
                }}
                data-test-hook="update-booking"
                disabled={props.updatingBooking}
              >
                Update
              </button>
            </div>
            <div className="Booking-buttonContainer">
              <button
                className={`Booking-button Booking-button--delete${
                  props.updatingBooking ? " Booking-button--disabled" : ""
                }`}
                onClick={() => {
                  document.body.classList.add("hasModal");
                  setDeleteBookingModalOpen(true);
                }}
                data-test-hook="delete-booking"
                disabled={props.updatingBooking}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <BookingDetails
          id={props.data.id}
          patchworkerName={
            props.data.patchworker
              ? props.data.patchworker.full_name
              : undefined
          }
          copyOfId={
            props.data.patchworker ? props.data.patchworker.copy_of_id : ""
          }
          enrolementLetter={
            props.data.enrolement_letter !== ""
              ? props.data.enrolement_letter
              : ""
          }
          rentalContract={
            props.data.rental_contract !== "" ? props.data.rental_contract : ""
          }
          depositReceived={props.data.deposit_received}
          notes={props.data.notes}
        />
      </div>
    </>
  );
}

Booking.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    patchworker: PropTypes.object,
    enrolement_letter: PropTypes.string,
    rental_contract: PropTypes.string,
    deposit_received: PropTypes.bool,
    notes: PropTypes.string,
  }).isRequired,
  handleSubmitBooking: PropTypes.func.isRequired,
  handleDeleteBooking: PropTypes.func.isRequired,
  updatingBooking: PropTypes.bool.isRequired,
  bookedDates: PropTypes.arrayOf(PropTypes.number),
  formHasError: PropTypes.bool.isRequired,
};

export default Booking;
