import React, { Component } from "react";

import PropTypes from "prop-types";
import UpdateEntityButton from "./UpdateEntityButton";

class Textarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    return (
      <div
        className={`Textarea Textarea--entityUpdate${
          this.props.extraClassNames ? " " + this.props.extraClassNames : ""
        }`}
      >
        <textarea
          name={this.props.attr}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          type="text"
          value={this.state.value}
        />
        <UpdateEntityButton
          actionType={this.props.actionType}
          active={this.props.initialValue !== this.state.value}
          extraClassNames="UpdateEntityButton--textarea"
          onClick={() => {
            this.props.handleSave({ [this.props.attr]: this.state.value });
          }}
        />
      </div>
    );
  }
}

Textarea.propTypes = {
  actionType: PropTypes.string,
  attr: PropTypes.string.isRequired,
  extraClassNames: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string
};

export default Textarea;
