import React, { Component } from "react";

import BookingsList from "./BookingsList";
import ImageUpload from "./ImageUpload";
import Input from "./Input";
import MyersBriggsProfile from "./MyersBriggsProfile";
import PropTypes from "prop-types";
import SchoolCard from "./SchoolCard";
import Section from "./Section";
import Select from "./Select";
import TagSection from "./TagSection";
import Textarea from "./Textarea";
import axios from "axios";

class EditProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
  }

  componentDidMount() {
    axios
      .get(`/api/profiles/${this.props.profileId}/`)
      .then((response) => this.setState({ data: response.data }))
      .catch((error) => console.log(JSON.stringify(error))); // eslint-disable-line no-console
  }

  handleUpdate(data) {
    axios
      .patch(`/api/profiles/${this.props.profileId}/`, data)
      .then((response) => this.setState({ data: response.data }))
      .catch((error) => console.log(JSON.stringify(error))); // eslint-disable-line no-console
  }

  handleAddTag(data) {
    const attr = Object.keys(data)[0];
    const addedTags = data[attr].split(",").map((tag) => tag.trim());
    const updatedTags = [...this.state.data[attr], ...addedTags];
    this.handleUpdate({ [attr]: updatedTags });
  }

  handleRemoveTag(tagToDelete, tagType) {
    const updatedTags = this.state.data[tagType].filter((tag) => {
      return tag !== tagToDelete;
    });
    this.handleUpdate({ [tagType]: updatedTags });
  }

  render() {
    if (!this.state.data) {
      return (
        <div className="PageLoader">
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <div className="EditProfile">
        <div className="EditProfile-header">
          <h1 className="EditProfile-title">Profile</h1>
          <p className="EditProfile-body">
            Complete your profile then find other Patchworkers to live with or
            start something exciting together.
          </p>
        </div>
        <div className="EditProfile-main">
          <div className="AccountOverview">
            <div className="AccountOverview-first Section--flex">
              <div className="ProfileImage">
                <div
                  className="Avatar Avatar--profile"
                  style={{
                    backgroundImage: `url(${this.state.data.image_thumbnail})`,
                  }}
                />
                <div className="ProfileImage-uploadContainer">
                  <ImageUpload attr="image" handleUpload={this.handleUpdate} />
                </div>
              </div>
            </div>

            <div className="AccountOverview-second Section--flex">
              <div className="AccountDetails Section--flex">
                <div className="AccountDetails-icon" />
                <h3 className="AccountDetails-name">
                  {this.state.data.full_name}
                </h3>
                <div className="AccountDetails-buttons Section--flex">
                  <a href="/accounts/password-change/" className="Button">
                    Change password
                  </a>
                  <a
                    href="/accounts/delete-account"
                    className="Button Button--delete"
                  >
                    Delete account
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Section
            title="Your programmes"
            body="Add the Programmes you have completed or are attending to unlock the full
            potential of Patchwork"
          >
            <div className="Grid SchoolsList">
              {this.state.data.profile_school_relations.map(
                (profile_school_relation) => {
                  return (
                    <SchoolCard
                      key={profile_school_relation.school_slug}
                      data={profile_school_relation}
                    />
                  );
                }
              )}
              <div className="Grid-item">
                <div className="SchoolCard SchoolCard--addSchool">
                  <div className="SchoolCard-content">
                    <div className="SchoolCard-body">
                      <div className="SchoolCard-plus">
                        <div className="SchoolCard-plusIcon" />
                      </div>
                    </div>
                    <div className="SchoolCard-footer">
                      <a
                        href="/accounts/add-school/"
                        className="Button"
                        data-test-hook="add-school"
                      >
                        Add school
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section title="About you">
            <div className="Divider" />
            <div className="EditProfile-formSection">
              <Input
                actionType="save"
                attr="nationality"
                handleAction={this.handleUpdate}
                initialValue={this.state.data.nationality}
                label="Nationality"
                placeholder="Nationality"
              />
              <Input
                actionType="save"
                attr="currently_living"
                handleAction={this.handleUpdate}
                initialValue={this.state.data.currently_living}
                label="Where I live"
                placeholder="Location"
              />
            </div>
            <div className="Divider" />
            <TagSection
              attr="languages_spoken"
              handleAddTag={this.handleAddTag}
              handleRemoveTag={(tagToRemove) => {
                this.handleRemoveTag(tagToRemove, "languages_spoken");
              }}
              label="Languages I speak"
              placeholder="Language"
              tags={this.state.data.languages_spoken}
            />
            <div className="Divider" />
          </Section>

          <div className="ConnectedAccounts">
            <div className="ConnectedAccounts-first">
              <h2 className="ConnectedAccounts-title">Connected accounts</h2>
              <div className="ConnectedAccounts-body">
                Let others find you by connecting your accounts here
              </div>
            </div>
            <div className="ConnectedAccounts-second">
              <Input
                actionType="save"
                attr="twitter_handle"
                extraClassNames="Input--social Input--noMargin twitter"
                handleAction={this.handleUpdate}
                initialValue={this.state.data.twitter_handle}
                placeholder="@twitterhandle"
              />
              <Input
                actionType="save"
                attr="facebook_handle"
                extraClassNames="Input--social Input--noMargin facebook"
                handleAction={this.handleUpdate}
                initialValue={this.state.data.facebook_handle}
                placeholder="facebook-username"
              />
              <Input
                actionType="save"
                attr="linkedin_handle"
                extraClassNames="Input--social Input--noMargin linkedin"
                handleAction={this.handleUpdate}
                initialValue={this.state.data.linkedin_handle}
                placeholder="linked-in-handle"
              />
              <Input
                actionType="save"
                attr="instagram_handle"
                extraClassNames="Input--social Input--noMargin instagram"
                handleAction={this.handleUpdate}
                initialValue={this.state.data.instagram_handle}
                placeholder="instagram-handle"
              />
            </div>
          </div>

          <Section
            body="In your own words tell us a bit about you, eg ambitions,
          motivations, goals or what you're wanting to achieve in the next few years."
            title="Introduce yourself"
          >
            <Textarea
              actionType="save"
              attr="intro_text"
              handleSave={this.handleUpdate}
              initialValue={this.state.data.intro_text}
              placeholder="Tell us a bit about you..."
            />
          </Section>
          <Section
            body="Let us know some of the things you're interested in or projects
          you're currently working on."
            title="Current projects & interests"
          >
            <TagSection
              attr="interests"
              handleAddTag={this.handleAddTag}
              handleRemoveTag={(tagToRemove) => {
                this.handleRemoveTag(tagToRemove, "interests");
              }}
              placeholder="Add a tag"
              tags={this.state.data.interests}
            />
          </Section>
          <Section body="Add your Myers Briggs profile" title="Your profile" />
          <div className="MyersBriggs-select">
            <Select
              attr="myers_briggs_profile"
              choices={window.patchwork.myers_briggs_choices}
              defaultValue={this.state.data.myers_briggs_profile}
              emptyText="Select a type"
              extraClassNames="Select--dark Select--narrow"
              onChange={this.handleUpdate}
            />
          </div>
          <MyersBriggsProfile items={this.state.data.myers_briggs_data} />
          <Section title="Your bookings">
            {this.state.data.bookings.length > 0 ? (
              <BookingsList
                bookings={this.state.data.bookings}
                patchworkerFullName={this.state.data.full_name}
                patchworkerCopyOfId={this.state.data.copy_of_id}
              />
            ) : (
              <p>None yet</p>
            )}
          </Section>
        </div>
      </div>
    );
  }
}

EditProfilePage.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default EditProfilePage;
