import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import React, { useState } from "react";

import Booking from "./Booking";
import BookingForm from "./BookingForm";
import Modal from "./Modal";
import PropTypes from "prop-types";
import RoomLink from "../components/RoomLink";
import axios from "axios";
import moment from "moment";

function Room(props) {
  const [bookings, setBookings] = useState(props.bookings);
  const [updatingBooking, setUpdatingBooking] = useState(false);
  const [newBookingModalOpen, setNewBookingModalOpen] = useState(false);
  const [bookingErrorIds, setBookingErrorIds] = useState([]);
  const [createBookingError, setCreateBookingError] = useState(false);

  function handleSubmitBooking(formData) {
    const bookingId = formData.get("id");
    if (bookingId) {
      removeIdFromErrorsArray(bookingId);
      updateBooking(formData);
    } else {
      setCreateBookingError(false);
      createBooking(formData);
    }
  }

  function updateBooking(formData) {
    setUpdatingBooking(true);
    const bookingId = formData.get("id");
    const url = `/api/bookings/${bookingId}/`;
    axios
      .patch(url, formData)
      .then((response) => {
        const updatedBookings = [...bookings];
        const indexOfUpdatedBooking = updatedBookings.findIndex(
          (booking) => response.data.id === booking.id
        );
        updatedBookings[indexOfUpdatedBooking] = response.data;
        setBookings(updatedBookings);
        setUpdatingBooking(false);
      })
      .catch((error) => {
        setBookingErrorIds([...bookingErrorIds, bookingId]);
        console.log(error.response);
      });
  }

  function handleDeleteBooking(bookingId) {
    setUpdatingBooking(true);
    removeIdFromErrorsArray(bookingId);
    const url = `/api/bookings/${bookingId}/`;
    axios
      .delete(url)
      .then(() => {
        const updatedBookings = bookings.filter(
          (booking) => booking.id !== bookingId
        );
        setBookings(updatedBookings);
        setUpdatingBooking(false);
        document.body.classList.remove("hasModal");
      })
      .catch((error) => console.log(error.response));
  }

  function createBooking(formData) {
    formData.append("room", props.id);
    setUpdatingBooking(true);
    const url = "/api/bookings/";
    axios
      .post(url, formData)
      .then((response) => {
        const updatedBookings = [...bookings, response.data];
        setBookings(updatedBookings);
        setUpdatingBooking(false);
        document.body.classList.remove("hasModal");
        setNewBookingModalOpen(false);
      })
      .catch((error) => {
        setCreateBookingError(true);
        console.log(error.response);
      });
  }

  function removeIdFromErrorsArray(bookingId) {
    setBookingErrorIds(
      bookingErrorIds.filter((id) => {
        return id !== bookingId;
      })
    );
  }

  const modalClassName = newBookingModalOpen
    ? "Modal--bookingForm isActive"
    : "Modal--bookingForm";

  let bookedDates = [];
  bookings.map((booking) => {
    const startDate = new moment(booking.start_date);
    const endDate = new moment(booking.end_date);

    for (let m = startDate; m <= endDate; m = m.add(1, "day")) {
      bookedDates.push(m.toDate().setHours(0, 0, 0, 0));
    }
  });

  return (
    <>
      <Modal heading="New booking" className={modalClassName}>
        <BookingForm
          handleSubmit={handleSubmitBooking}
          bookedDates={bookedDates}
          data={{}}
          modalOpen={newBookingModalOpen}
          handleClose={() => {
            document.body.classList.remove("hasModal");
            setNewBookingModalOpen(false);
          }}
          hasError={createBookingError}
        />
      </Modal>
      <div className="RoomListing" data-test-hook="room-listing">
        <h2 className="RoomListing-title" data-test-hook="room-title">
          {props.title}
        </h2>
        <div className="RoomListing-inner">
          <RoomLink url={props.url} headerImage={props.header_image} />
          {props.manageAvailability && (
            <div
              className="RoomListing-bookings"
              data-test-hook="room-bookings"
            >
              <h3 className="RoomListing-bookingsHeader">Bookings</h3>
              {bookings.length ? (
                <div className="RoomListing-bookingsList">
                  {bookings.map((booking) => {
                    // Remove the dates included in this booking from the
                    // booked dates so they won't be greyed out
                    const startIndex = bookedDates.indexOf(
                      new Date(booking.start_date).setHours(0, 0, 0, 0)
                    );
                    const endIndex = bookedDates.indexOf(
                      new Date(booking.end_date).setHours(0, 0, 0, 0)
                    );
                    const itemsToRemove = endIndex + 1 - startIndex;
                    let bookedDatesExcCurrent = [...bookedDates];
                    bookedDatesExcCurrent.splice(startIndex, itemsToRemove);
                    return (
                      <Booking
                        key={booking.id}
                        formHasError={bookingErrorIds.includes(
                          booking.id.toString()
                        )}
                        data={booking}
                        handleSubmitBooking={handleSubmitBooking}
                        handleDeleteBooking={handleDeleteBooking}
                        updatingBooking={updatingBooking}
                        bookedDates={bookedDatesExcCurrent}
                      />
                    );
                  })}
                </div>
              ) : (
                <p
                  className="RoomListing-noBookings"
                  data-test-hook="no-bookings"
                >
                  No bookings yet
                </p>
              )}
              <button
                className="Button"
                data-test-hook="new-booking"
                onClick={() => {
                  document.body.classList.add("hasModal");
                  setNewBookingModalOpen(true);
                }}
              >
                New booking
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

Room.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  header_image: PropTypes.string.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  manageAvailability: PropTypes.bool.isRequired,
  booked_dates: PropTypes.arrayOf(PropTypes.string),
};

export default Room;
