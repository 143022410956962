import PropTypes from "prop-types";
import React from "react";

const SchoolCard = props => {
  return (
    <div className="Grid-item">
      <div className="SchoolCard" data-test-hook="school-card">
        <div
          className="SchoolCard-image Card-image"
          style={{ backgroundImage: `url(${props.data.school_promo_image})` }}
        />
        <div className="SchoolCard-content">
          <div className="SchoolCard-body">
            <h3 className="SchoolCard-title">{props.data.school_name}</h3>
            {props.data.programme_relations.map(programme_relation => {
              return (
                <p
                  key={programme_relation.programme_name}
                  className="SchoolCard-detail"
                  data-test-hook="school-card-detail"
                >
                  {programme_relation.cohort === ""
                    ? programme_relation.programme_name
                    : `${programme_relation.programme_name} - ${
                        programme_relation.cohort
                      }`}
                </p>
              );
            })}
          </div>
          {props.data.email_validated ? (
            <div className="SchoolCard-footer">
              <a
                href={`/accounts/${props.data.school_slug}/add-programme/`}
                className="Button"
                data-test-hook="add-programme"
              >
                Add a programme
              </a>
            </div>
          ) : (
            <div>Awaiting email validation</div>
          )}
        </div>
      </div>
    </div>
  );
};

SchoolCard.propTypes = {
  data: PropTypes.shape({
    programme_relations: PropTypes.arrayOf(
      PropTypes.shape({
        programme_name: PropTypes.string.isRequired,
        cohort: PropTypes.string.isRequired
      })
    ),
    school_name: PropTypes.string.isRequired,
    school_promo_image: PropTypes.string.isRequired,
    school_slug: PropTypes.string.isRequired,
    email_validated: PropTypes.bool.isRequired
  }).isRequired
};

export default SchoolCard;
