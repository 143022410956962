import PropTypes from "prop-types";
import React from "react";

function RoomList(props) {
  return (
    <a href={props.url} className="RoomLink">
      <img className="RoomLink-image" src={props.headerImage} />
    </a>
  );
}

RoomList.propTypes = {
  url: PropTypes.string.isRequired,
  headerImage: PropTypes.string.isRequired,
};

export default RoomList;
