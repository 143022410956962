import PropTypes from "prop-types";
import React from "react";

function Modal(props) {
  let className;
  props.className
    ? (className = `Modal ${props.className}`)
    : (className = "Modal");
  return (
    <div className={className}>
      <div className="Modal-content">
        <h1>{props.heading}</h1>
        {props.children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default Modal;
