import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import axios from "axios";

function AutoCompleteInput(props) {
  const [value, setValue] = useState(props.initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const timeoutId = useRef();

  useEffect(() => {
    setActiveSuggestionIndex(0);
    if (suggestions.length) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [suggestions]);

  function handleChange(event) {
    const searchTerm = event.target.value;
    setValue(searchTerm);
    // if the user keeps typing, stop the API call!
    clearTimeout(timeoutId.current);
    // don't make an API call with no data or same data as initial
    if (!searchTerm.trim() || searchTerm === props.initialValue) return;
    // capture the timeoutId so we can stop the call if the user
    // keeps typing
    timeoutId.current = setTimeout(() => {
      axios
        .get(`/api/profiles/?search=${searchTerm.toLowerCase()}`)
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
  }

  function onKeyDown(event) {
    if (event.keyCode === 40) {
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
    if (event.keyCode === 38) {
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    if (event.keyCode === 13) {
      handleSelect(activeSuggestionIndex);
    }
  }

  function handleSelect(index) {
    const patchworker = suggestions[index];
    setSuggestions([]);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setValue(patchworker.full_name);
    props.handleSelect(patchworker);
  }

  return (
    <div className="AutoCompleteInput">
      <div className="Input Input--rounded">
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          name={props.name}
        />
      </div>
      {showSuggestions && value && (
        <div className="AutoCompleteInput-suggestions">
          {suggestions.length ? (
            suggestions.map((suggestion, index) => {
              const active =
                activeSuggestionIndex % suggestions.length === index;
              return (
                <p
                  key={suggestion.id}
                  className={`AutoCompleteInput-suggestion ${
                    active ? "active" : ""
                  }`}
                  data-test-hook="suggestion"
                  onClick={() => {
                    handleSelect(index);
                  }}
                >
                  {suggestion.full_name}
                </p>
              );
            })
          ) : (
            <p className={"AutoCompleteInput-suggestion"}>No results</p>
          )}
        </div>
      )}
    </div>
  );
}

AutoCompleteInput.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default AutoCompleteInput;
