const $ = window.$;

export default function LoadArticles() {
  const $articleGrids = $(".Grid--articles");
  if ($articleGrids.length == 0) return;

  $articleGrids.each((index, item) => {
    const $articleGrid = $(item);
    const $loadMoreButton = $articleGrid.parent().find(".load-article-button");
    const totalItems = $articleGrid.data("total-items");

    $loadMoreButton.on("click", () => {
      const numItems = $articleGrid.find(".Grid-item").length;
      const pageId = $articleGrid.data("explore-page-id");
      const url = `/explore/load-articles/${pageId}`;

      $.get(`${url}?offset=${numItems}`, html => {
        $articleGrid.append(html);
        const newNumItems = $articleGrid.find(".Grid-item").length;
        if (newNumItems >= totalItems) {
          $loadMoreButton.hide();
        }
      });
    });
  });
}
