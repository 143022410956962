import Cookies from "js-cookie";
import EditProfile from "./EditProfile";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

window.addEventListener("load", () => {
  const csrfToken = Cookies.get("csrftoken");
  axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
  axios.defaults.headers.patch["X-CSRFToken"] = csrfToken;
  axios.defaults.headers.delete["X-CSRFToken"] = csrfToken;

  const editProfile = document.getElementById("edit-profile");
  if (!editProfile) return;
  ReactDOM.render(
    <EditProfile profileId={window.patchwork.profileId} />,
    editProfile
  );
});
