import PropTypes from "prop-types";
import React from "react";

const UpdateEntityButton = props => {
  return (
    <button
      className={`Button UpdateEntityButton--${
        props.actionType
      } UpdateEntityButton${
        props.extraClassNames ? " " + props.extraClassNames : ""
      }${props.active ? " active" : ""}`}
      onClick={props.onClick}
    >
      {props.actionType === "save" ? (
        <p>Save</p>
      ) : (
        <div className="UpdateEntityButton-icon" />
      )}
    </button>
  );
};

UpdateEntityButton.propTypes = {
  actionType: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  extraClassNames: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default UpdateEntityButton;
