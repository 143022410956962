const $ = window.$;

import bodymovin from "bodymovin";

export default function Hero() {
  const $heroContent = $(".Hero-content");

  if ($heroContent.length) {
    setTimeout(() => {
      bodymovin.loadAnimation({
        container: $heroContent[0],
        path: window.patchwork.heroData,
        renderer: "svg",
        loop: true,
        autoplay: true,
        name: "Hero"
      });
    }, 500);
  }
}
