const $ = window.$;

export default function Dropdown() {
  const $dropdowns = $("[data-js='dropdown']");
  if ($dropdowns.length == 0) return;

  $dropdowns.each((index, el) => {
    const $dropdown = $(el);
    const $dropdownContent = $dropdown.find(".Dropdown-content");
    const $current = $dropdown.find("[data-js='dropdown-current']");
    $current.on("click", () => {
      $current.toggleClass("isActive");
      $dropdownContent.toggleClass("isActive");
    });
  });
}
