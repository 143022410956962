import React, { Component } from "react";

import PropTypes from "prop-types";

class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append(this.props.attr, file);
    this.props.handleUpload(formData);
  }

  render() {
    return (
      <div className="ImageUpload">
        <div className="Button ImageUploadButton">
          <div className="ImageUploadButton-icon" />
        </div>
        <input
          type="file"
          name={this.props.attr}
          id={this.props.attr}
          onChange={this.handleInputChange}
        />
      </div>
    );
  }
}

ImageUpload.propTypes = {
  attr: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired
};

export default ImageUpload;
