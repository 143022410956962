import PropTypes from "prop-types";
import React from "react";

const MyersBriggsProfile = props => {
  return (
    <div className="MyersBriggs--edit">
      {props.items.map(item => {
        return (
          <div className="MyersBriggs-section" key={item.letter}>
            <div className="MyersBriggs-letter Section--flex">
              <h1>{item.letter}</h1>
            </div>
            <div className="MyersBriggs-description">
              <h3>{item.type}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

MyersBriggsProfile.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      letter: PropTypes.string,
      type: PropTypes.string
    }).isRequired
  )
};

export default MyersBriggsProfile;
