const $ = window.$;

var $schoolSelect = $("#id_school");
var $programmeSelect = $("#id_programme");

if ($schoolSelect.length && $programmeSelect.length) {
  // If a School is already selected when we land on the form,
  // strip out all the Programme select options where the Programme
  // does not belong to this School
  var schoolSelectVal = $schoolSelect.val();
  if (schoolSelectVal != "") {
    var unselectedSchools = window.patchwork.schoolData.filter(function(
      school
    ) {
      return school.id != schoolSelectVal;
    });

    unselectedSchools.forEach(function(school) {
      school.programme_set.forEach(function(programme) {
        $("#id_programme option[value=" + programme.id + "]").remove();
      });
    });
  } else {
    getSchoolProgrammes(schoolSelectVal);
  }
  $schoolSelect.on("change", event => {
    getSchoolProgrammes(event.target.value);
  });
}

function getSchoolProgrammes(value) {
  // On School change, remove all the current Programme select options, except
  // for the first, which is the default
  $("#id_programme option:not(:first)").remove();
  // If a new School has been selected, find it's related Programmes
  // and use them to re-populate the Programme select.
  if (value !== "") {
    var school = window.patchwork.schoolData.filter(function(item) {
      return item.id == value;
    })[0];
    $(school.programme_set).each(function(i, programme) {
      var $option = $(
        "<option value=" + programme.id + ">" + programme.name + "</option>"
      );
      $programmeSelect.append($option);
    });
  }
}
