const $ = window.$;

export default function FilterEvents() {
  const $eventNav = $("#event-nav");
  const $eventList = $("EventList");
  if (!$eventNav.length && !$eventList.length) return;

  const $showAllButton = $eventNav.find("#show-all");
  const $showSavedButton = $eventNav.find("#show-saved");

  const baseUrl = `/explore/filter-events/${$eventNav.attr(
    "data-explore-page-id"
  )}`;

  $showAllButton.click(function() {
    $showSavedButton.removeClass("isActive");
    $showAllButton.addClass("isActive");
    $("#events").load(baseUrl);
  });

  $showSavedButton.click(function() {
    $showAllButton.removeClass("isActive");
    $showSavedButton.addClass("isActive");
    $("#events").load(`${baseUrl}?saved=true/`);
  });
}
