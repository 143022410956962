import PropTypes from "prop-types";
import React from "react";

const Tag = props => {
  return (
    <div className="Tag">
      <p>{props.tagName}</p>
      <div
        className="DeleteButton"
        onClick={() => {
          props.handleDelete(props.tagName);
        }}
      >
        <div className="DeleteButton-icon" />
      </div>
    </div>
  );
};

Tag.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  tagName: PropTypes.string.isRequired
};

export default Tag;
