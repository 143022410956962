import React, { useState } from "react";

import Property from "./Property";
import Toggle from "./Toggle";
import axios from "axios";

function LandlordApp() {
  const [manageAvailability, setManageAvailability] = useState(
    window.patchwork.manage_availability
  );
  const [updatingAvailability, setUpdatingAvailability] = useState(false);

  function handleAvailabilityChange(value) {
    setUpdatingAvailability(true);
    setManageAvailability(value);
    const url = `/api/landlord-profiles/${window.patchwork.profile_id}/set_manage_availability/`;
    axios
      .post(url, { manage_availability: value })
      .then(() => {
        setUpdatingAvailability(false);
      })
      .catch(error => console.log(error.response));
  }

  return (
    <div className="Dashboard">
      <div className="Dashboard-inner">
        <h1 className="Dashboard-heading">Dashboard</h1>
        <p className="Dashboard-subheading">
          Welcome to your dashboard. Here you can publish your property for
          Patchworkers to find, manage your room availability calendar and
          preview how your properties are viewed by other Patchworkers.
        </p>
        <div className="Dashboard-main">
          {window.patchwork.property_pages.length > 0 ? (
            <>
              <div className="Dashboard-manageAvailability">
                <Toggle
                  value={manageAvailability}
                  handleChange={handleAvailabilityChange}
                  updating={updatingAvailability}
                  label="Manage availability"
                  value_true="On"
                  value_false="Off"
                />
              </div>
              <div className="Dashboard-propertyList">
                {window.patchwork.property_pages.map(propertyPage => (
                  <Property
                    key={propertyPage.title}
                    {...propertyPage}
                    manageAvailability={manageAvailability}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="Dashboard-noProperties">
              <h2>Welcome to your Patchwork dashboard</h2>
              <p>
                We are in the process of associating your account to your
                property. Once we have linked you to your properties you will be
                able to manage its bookings calendar.
              </p>
              <p>
                If this page hasnt been updated within 24hrs please contact us
                at{" "}
                <a href={`mailto:${window.patchwork.contact_email}`}>
                  landlords@patchwork.me
                </a>{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LandlordApp;
