import PropTypes from "prop-types";
import React from "react";

function BookingDates(props) {
  return (
    <div className="BookingDates">
      <p className="BookingDates-date">{props.startDate}</p>
      <span className="Arrow-icon" aria-hidden="true" />
      <p className="BookingDates-date">{props.endDate}</p>
    </div>
  );
}

BookingDates.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default BookingDates;
