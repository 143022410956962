import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import queryString from "query-string";

export default function RoomAvailability() {
  const roomAvailabilityRoot = document.getElementById("room-availability");

  if (roomAvailabilityRoot) {
    const availabilityManaged =
      roomAvailabilityRoot.getAttribute("data-room-availability-managed") ===
      "true";

    const mountPoint = document.createElement("div");
    roomAvailabilityRoot.parentNode.insertBefore(
      mountPoint,
      roomAvailabilityRoot.nextSibling
    );

    const parsedQueryString = queryString.parse(window.location.search);
    let startDate = null;
    let endDate = null;

    if (typeof parsedQueryString["start_date"] !== "undefined") {
      startDate = parsedQueryString["start_date"];
    }

    if (typeof parsedQueryString["end_date"] !== "undefined") {
      endDate = parsedQueryString["end_date"];
    }

    ReactDOM.render(
      <RoomAvailabilityWidget
        roomAvailabilityRoot={roomAvailabilityRoot}
        availabilityManaged={availabilityManaged}
        startDate={startDate}
        endDate={endDate}
      />,
      mountPoint
    );
  }
}

class RoomAvailabilityWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: props.startDate ? moment(props.startDate) : null,
      endDate: props.endDate ? moment(props.endDate) : null
    };

    this.handleDatesChanged = this.handleDatesChanged.bind(this);
    this.checkIfDateBlocked = this.checkIfDateBlocked.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleDatesChanged(startDate, endDate, targetEl) {
    this.setState({ startDate: startDate, endDate: endDate }, () => {
      const processedStartDate = this.state.startDate
        ? new Date(this.state.startDate)
        : null;
      const processedEndDate = this.state.endDate
        ? new Date(this.state.endDate)
        : null;
      targetEl.value = JSON.stringify({
        startDate: processedStartDate,
        endDate: processedEndDate
      });
    });
  }

  checkIfDateBlocked(moment) {
    const bookedDates = window.patchwork.booked_dates.map(dateString => {
      return new Date(dateString).setHours(0, 0, 0, 0);
    });

    if (bookedDates.includes(moment.toDate().setHours(0, 0, 0, 0))) {
      return true;
    } else {
      return false;
    }
  }

  handleClick() {
    const formattedStartDate = this.state.startDate
      ? this.state.startDate.format("YYYY-MM-DD")
      : "";
    const formattedEndDate = this.state.endDate
      ? this.state.endDate.format("YYYY-MM-DD")
      : "";
    const queryString = `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    window.location = `${window.patchwork.enquiry_form_url}${queryString}`;
  }

  render() {
    return (
      <div className="Availability">
        <h3 className="Availability-title">Required dates</h3>
        <div className="Availability-dateContainer">
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="start_date"
            endDate={this.state.endDate}
            endDateId="end_date"
            onDatesChange={({ startDate, endDate }) => {
              this.handleDatesChanged(
                startDate,
                endDate,
                this.props.roomAvailabilityRoot
              );
            }}
            isDayBlocked={
              this.props.availabilityManaged
                ? this.checkIfDateBlocked
                : () => false
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            showClearDates
            numberOfMonths={1}
          />
        </div>
        {!this.props.availabilityManaged && (
          <p className="Availability-warning">
            We currently don't have full visibility on this property's
            availability.
          </p>
        )}
        <div className="Availability-button">
          <button
            className="Button"
            onClick={this.handleClick}
            href="{% url 'enquire' page.get_parent.get_parent.slug page.get_parent.slug page.slug %}{% if request.GET.urlencode %}?{{ request.GET.urlencode }}{% endif %}"
          >
            Enquire now
          </button>
        </div>
      </div>
    );
  }
}

RoomAvailabilityWidget.propTypes = {
  roomAvailabilityRoot: PropTypes.object.isRequired,
  availabilityManaged: PropTypes.bool.isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string
};
