const $ = window.$;

export default function Gallery() {
  const $body = $("body");
  const $gallery = $("[data-js='gallery']");
  if ($gallery.length == 0) return;
  const $triggers = $("[data-js='gallery-trigger']");
  const $content = $gallery.find("[data-js='gallery-inner']");
  const $carousel = $gallery.find("[data-js='gallery-carousel']");
  const $thumbnails = $gallery.find("[data-js='gallery-thumbnail']");
  let isActive = false;

  $carousel.slick({
    fade: true,
    dots: true
  });

  function toggleGallery() {
    isActive = !isActive;
    $gallery.toggleClass("isActive");
    $content.toggleClass("isActive");
    $body.toggleClass("hasModal");
  }

  $thumbnails.on("click", e => {
    const index = $(e.target).index();
    $carousel.slick("slickGoTo", index);
  });

  $triggers.on("click", () => {
    toggleGallery();
  });
}
