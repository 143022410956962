import React, { useState } from "react";

import PrivateDocument from "./PrivateDocument";
import PropTypes from "prop-types";

function BookingDetails(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={`BookingDetails${isExpanded ? " expanded" : ""}`}>
      <button
        className="BookingDetails-expand"
        data-test-hook="booking-details-expand"
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <h3 className="BookingDetails-heading">Booking details</h3>
        <div className="BookingDetails-expandButton">
          {isExpanded ? "Hide" : "Expand"}
          <img src={`${window.patchwork.staticUrl}icons/triangle-down.svg`} />
        </div>
      </button>

      <div className="BookingDetails-content">
        <p className="BookingDetails-section" data-test-hook="patchworker-name">
          {props.patchworkerName
            ? `${props.patchworkerName}`
            : "Patchworker not assigned"}
        </p>
        <p className="BookingDetails-section">
          {props.depositReceived ? "Deposit received" : "Deposit required"}
        </p>
        {props.copyOfId && (
          <div className="BookingDetails-section">
            <h3 className="BookingDetails-sectionLabel">Copy of ID</h3>
            <PrivateDocument s3Key={props.copyOfId} bookingId={props.id} />
          </div>
        )}

        {props.enrolementLetter && (
          <div className="BookingDetails-section">
            <h3 className="BookingDetails-sectionLabel">Enrolement letter</h3>
            <PrivateDocument
              s3Key={props.enrolementLetter}
              bookingId={props.id}
            />
          </div>
        )}

        {props.rentalContract && (
          <div className="BookingDetails-section">
            <h3 className="BookingDetails-sectionLabel">Rental contract</h3>
            <PrivateDocument
              s3Key={props.rentalContract}
              bookingId={props.id}
            />
          </div>
        )}

        {props.notes !== "" && (
          <div className="BookingDetails-section">
            <h3 className="BookingDetails-sectionLabel">Notes</h3>
            <p>{props.notes}</p>
          </div>
        )}
      </div>
    </div>
  );
}

BookingDetails.propTypes = {
  id: PropTypes.number,
  patchworkerName: PropTypes.string,
  copyOfId: PropTypes.string,
  enrolementLetter: PropTypes.string,
  rentalContract: PropTypes.string,
  depositReceived: PropTypes.bool,
  notes: PropTypes.string,
};

export default BookingDetails;
