export default function Rules() {
  const body = document.querySelector("[data-js='body']");
  const rules = document.querySelector("[data-js='rules']");
  if (!rules) return;
  const trigger = document.querySelectorAll("[data-js='rules-trigger']");
  const content = rules.querySelector("[data-js='rules-content']");

  function toggleRules() {
    body.classList.toggle("hasModal");
    rules.classList.toggle("isActive");
    content.classList.toggle("isActive");
  }

  // Open rules when clicking "add" button
  trigger.forEach(function(el) {
    el.addEventListener("click", function() {
      toggleRules();
    });
  });
}
