import React, { useEffect, useState } from "react";

import AutoCompleteInput from "./AutoCompleteInput";
import Checkbox from "../components/Checkbox";
import DatePicker from "./DatePicker";
import PrivateDocument from "../components/PrivateDocument";
import PropTypes from "prop-types";
import S3Input from "./S3Input";
import moment from "moment";

function BookingForm(props) {
  const initialDates = {
    startDate: props.data.start_date ? moment(props.data.start_date) : null,
    endDate: props.data.end_date ? moment(props.data.end_date) : null,
  };

  const [dates, setDates] = useState(initialDates);
  const [showWarning, setShowWarning] = useState(false);
  const [notes, setNotes] = useState(props.data.notes ? props.data.notes : "");
  const [patchworker, setPatchworker] = useState(
    props.data.patchworker ? props.data.patchworker : null
  );
  const [enrolementLetter, setEnrolementLetter] = useState(
    props.data.enrolement_letter ? props.data.enrolement_letter : ""
  );
  const [rentalContract, setRentalContract] = useState(
    props.data.rental_contract ? props.data.rental_contract : ""
  );
  const [copyOfId, setCopyOfId] = useState(
    props.data.patchworker && props.data.patchworker.copy_of_id
      ? props.data.patchworker.copy_of_id
      : ""
  );
  const [depositReceived, setDepositReceived] = useState(
    props.data.deposit_received ? props.data.deposit_received : false
  );

  useEffect(() => {
    // If the modal gets closed then reset inputs back to initial values,
    // except the file inputs, which cannot be set programmatically
    setDates(initialDates);
    setNotes(props.data.notes ? props.data.notes : "");
    setDepositReceived(
      props.data.deposit_received ? props.data.deposit_received : false
    );
    setPatchworker(props.data.patchworker ? props.data.patchworker : null);
  }, [props.modalOpen]);

  function handleSave() {
    if (dates.startDate && dates.endDate) {
      const startDate = dates.startDate.format("YYYY-MM-DD");
      const endDate = dates.endDate.format("YYYY-MM-DD");
      let formData = new FormData();
      if (props.data.id) formData.append("id", props.data.id);
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      formData.append("notes", notes);
      formData.append("deposit_received", depositReceived);
      if (patchworker) {
        formData.append("patchworker_id", patchworker.id);
        formData.append("patchworker_copy_of_id", copyOfId);
      }
      if (copyOfId) {
        formData.append("patchworker_copy_of_id", copyOfId);
      }
      formData.append("enrolement_letter", enrolementLetter);
      formData.append("rental_contract", rentalContract);
      props.handleSubmit(formData);
    } else {
      setShowWarning(true);
    }
  }

  function handleSetDates(newDates) {
    setShowWarning(false);
    setDates(newDates);
  }

  return (
    <div className="BookingForm">
      <div className="BookingForm-dates">
        <DatePicker
          setDates={(newDates) => {
            handleSetDates(newDates);
          }}
          startDate={dates.startDate}
          endDate={dates.endDate}
          bookedDates={props.bookedDates}
          showClearDates={props.showClearDates}
        />
        <p
          className={`BookingForm-warning${
            showWarning ? " BookingForm-warning--active" : ""
          }`}
        >
          Please enter both a start and a finish date
        </p>
      </div>
      <AutoCompleteInput
        initialValue={
          props.data.patchworker ? props.data.patchworker.full_name : ""
        }
        handleSelect={(patchworker) => {
          setCopyOfId(patchworker.copy_of_id);
          setPatchworker(patchworker);
        }}
        name="patchworker"
      />
      <div className="BookingForm-section">
        <Checkbox
          label="Deposit received"
          checked={depositReceived}
          handleClick={() => {
            setDepositReceived(!depositReceived);
          }}
        />
      </div>
      <div className="BookingForm-section">
        <h3 className="BookingForm-sectionLabel">Copy of ID</h3>
        {copyOfId && (
          <PrivateDocument
            s3Key={copyOfId}
            bookingId={props.data.id ? props.data.id : null}
          />
        )}
        <S3Input
          label="Copy of ID"
          name="copy-of-id"
          dest="patchworker_ids"
          handleChange={setCopyOfId}
          value={copyOfId}
        />
      </div>
      <div className="BookingForm-section">
        <h3 className="BookingForm-sectionLabel">Enrolement letter</h3>
        {enrolementLetter && (
          <PrivateDocument
            s3Key={enrolementLetter}
            bookingId={props.data.id ? props.data.id : null}
          />
        )}
        <S3Input
          label="Enrolement letter"
          name="enrolement-letter"
          dest="booking_documents"
          handleChange={setEnrolementLetter}
          value={enrolementLetter}
        />
      </div>
      <div className="BookingForm-section">
        <h3 className="BookingForm-sectionLabel">Rental contract</h3>
        {rentalContract && (
          <PrivateDocument
            s3Key={rentalContract}
            bookingId={props.data.id ? props.data.id : null}
          />
        )}
        <S3Input
          label="Rental contract"
          name="rental-contract"
          dest="booking_documents"
          handleChange={setRentalContract}
          value={rentalContract}
        />
      </div>

      <div className="BookingForm-notes">
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add notes..."
        />
      </div>
      <div className="BookingForm-buttons">
        <div className="BookingForm-buttonContainer">
          <button className="Button Button--delete" onClick={props.handleClose}>
            Cancel
          </button>
        </div>
        <div className="BookingForm-buttonContainer">
          <button
            className="Button"
            onClick={handleSave}
            data-test-hook="save-booking"
            disabled={showWarning}
          >
            Save
          </button>
        </div>
      </div>
      <p
        className={`BookingForm-warning${
          showWarning || props.hasError ? " BookingForm-warning--active" : ""
        }`}
      >
        {props.hasError
          ? "Something went wrong"
          : "Please fix the errors above"}
      </p>
    </div>
  );
}

BookingForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  bookedDates: PropTypes.arrayOf(PropTypes.number).isRequired,
  showClearDates: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    patchworker: PropTypes.object,
    enrolement_letter: PropTypes.string,
    rental_contract: PropTypes.string,
    deposit_received: PropTypes.bool,
    notes: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default BookingForm;
