import PropTypes from "prop-types";
import React from "react";
import axios from "axios";

function PrivateDocument(props) {
  const keySplit = props.s3Key.split("/");
  const dirname = keySplit[0];
  const filename = keySplit[1];
  const url = `/api/booking-documents/${props.bookingId}/${dirname}/${filename}/`;

  return (
    <button
      className="PrivateDocument"
      onClick={() => {
        const windowReference = window.open("", "_blank");
        axios
          .get(url)
          .then((response) => {
            windowReference.location = response.data.url;
            windowReference.focus();
          })
          .catch((error) => {
            windowReference.close();
            console.log(error);
          });
      }}
      aria-label={`View ${filename}`}
    >
      <img
        src={`${window.patchwork.staticUrl}icons/document.svg`}
        alt="document icon"
        className="PrivateDocument-icon"
      />
      <p className="PrivateDocument-filename">{filename}</p>
    </button>
  );
}

PrivateDocument.propTypes = {
  s3Key: PropTypes.string,
  bookingId: PropTypes.number,
};

export default PrivateDocument;
