import React, { useState } from "react";

import PropTypes from "prop-types";
import Room from "./Room";
import Toggle from "./Toggle";
import axios from "axios";

function Property(props) {
  const [listProperty, setListProperty] = useState(props.show_in_menus);
  const [updatingListProperty, setUpdatingListProperty] = useState(false);

  function handleListPropertyChange(value) {
    setUpdatingListProperty(true);
    setListProperty(value);
    const url = `/api/property-pages/${props.id}/`;
    axios
      .patch(url, { show_in_menus: value })
      .then(() => {
        setUpdatingListProperty(false);
      })
      .catch(error => console.log(error.response));
  }

  return (
    <div className="PropertyListing" data-test-hook="property-listing">
      <div className="PropertyListing-heading">
        <div>
          <h1 className="PropertyListing-title" data-test-hook="property-title">
            {props.title}
          </h1>
        </div>
        <a href={props.url} className="Button">
          View property listing
        </a>
      </div>
      <p className="PropertyListing-update">
        If you would like us to update the details on this listing please
        contact us at{" "}
        <a
          href={`mailto:landlords@patchwork.me?subject=Landlord request to update ${props.title}`}
        >
          landlords@patchwork.me
        </a>
      </p>
      <div className="PropertyListing-approve">
        <Toggle
          value={listProperty}
          handleChange={handleListPropertyChange}
          updating={updatingListProperty}
          label="List property"
          value_true="Listed"
          value_false="Unlisted"
        />
      </div>

      <div className="PropertyListing-rooms">
        {props.room_pages.map(roomPage => {
          return (
            <Room
              {...roomPage}
              key={roomPage.title}
              manageAvailability={props.manageAvailability}
            />
          );
        })}
      </div>
    </div>
  );
}

Property.propTypes = {
  id: PropTypes.number.isRequired,
  manageAvailability: PropTypes.bool.isRequired,
  room_pages: PropTypes.arrayOf(PropTypes.object),
  show_in_menus: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default Property;
