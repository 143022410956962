import PropTypes from "prop-types";
import React from "react";

function Checkbox(props) {
  return (
    <div className="Checkbox">
      <button
        className={`Checkbox-label${props.checked ? " checked" : ""}`}
        onClick={props.handleClick}
      >
        {props.label}
      </button>
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default Checkbox;
