import React, { Component } from "react";

import PropTypes from "prop-types";
import UpdateEntityButton from "./UpdateEntityButton";

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key == "Enter" && this.props.initialValue !== this.state.value) {
      this.handleSave();
    }
  }

  handleSave() {
    this.props.handleAction({ [this.props.attr]: this.state.value });
    if (this.props.actionType === "add") {
      this.setState({ value: "" });
    }
  }

  render() {
    return (
      <div className="Form-field">
        {this.props.label && (
          <label className="Form-label" htmlFor={this.props.attr}>
            {this.props.label}
          </label>
        )}
        <div
          className={`Input Input--rounded Input--entityUpdate${
            this.props.extraClassNames ? " " + this.props.extraClassNames : ""
          }`}
        >
          <input
            name={this.props.attr}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            placeholder={this.props.placeholder}
            type="text"
            value={this.state.value}
          />
          <UpdateEntityButton
            active={this.props.initialValue !== this.state.value}
            actionType={this.props.actionType}
            extraClassNames="UpdateEntityButton--input"
            onClick={this.handleSave}
          />
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  actionType: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  extraClassNames: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired
};

export default Input;
