const $ = window.$;
import { throttle } from "lodash";

export default function ResponsiveBackgroundImages() {
  const $els = $("[data-js='responsive-background-image']");
  if (!$els.length) return;

  handleResize();

  $(window).on("resize", throttle(handleResize, 100));

  function handleResize() {
    if (window.innerWidth >= convertRemToPixels(60)) {
      $els.each((index, el) => {
        $(el).css("background-image", `url(${$(el).data("desktop-image")})`);
      });
      return;
    }
    if (window.innerWidth >= convertRemToPixels(46)) {
      $els.each((index, el) => {
        $(el).css("background-image", `url(${$(el).data("tablet-image")})`);
      });
      return;
    }
    $els.each((index, el) => {
      $(el).css("background-image", `url(${$(el).data("mobile-image")})`);
    });
  }

  function convertRemToPixels(rem) {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }
}
