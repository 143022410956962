export default function SlideNav() {
  const body = document.querySelector("[data-js='body']");
  const slidenav = document.querySelector("[data-js='slidenav']");

  if (!slidenav) return;

  const trigger = slidenav.querySelector("[data-js='slidenav-trigger']");
  const content = slidenav.querySelector("[data-js='slidenav-content']");

  function toggleNav() {
    body.classList.toggle("hasSlideNav");
    trigger.classList.toggle("isActive");
    content.classList.toggle("isActive");
  }

  trigger.addEventListener("click", toggleNav);
}
