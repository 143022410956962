const $ = window.$;
import Cookies from "js-cookie";
import axios from "axios";

export default function SaveEvent() {
  const $parent = $(".EventList, .Event-main");
  if (!$parent.length) return;

  const csrfToken = Cookies.get("csrftoken");
  axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
  axios.defaults.headers.patch["X-CSRFToken"] = csrfToken;
  axios.defaults.headers.delete["X-CSRFToken"] = csrfToken;

  // Attach event to parent to account for event lists which may have items loaded by ajax
  $parent.on("click", ".SaveButton", e => {
    const $button = $(e.target);

    const eventPageId = $button.attr("data-event-page-id");
    const profileId = window.patchwork.profileId;
    const data = { event_page_id: eventPageId };

    if ($button.hasClass("SaveButton--saving")) {
      // Bail out if we're already underway (perhaps the user double clicked etc)
      return;
    } else {
      $button.addClass("SaveButton--saving");
    }

    let url;
    if ($button.hasClass("SaveButton--saved")) {
      $button.removeClass("SaveButton--saved");
      url = `/api/profiles/${profileId}/remove_event_interested_in/`;
    } else {
      $button.addClass("SaveButton--saved");
      url = `/api/profiles/${profileId}/add_event_interested_in/`;
    }

    axios
      .post(url, data)
      .then(() => {
        // We're done, so take the double click blocker off
        $button.removeClass("SaveButton--saving");

        // Add the profile avatar to the LinkedProfiles block if we've just added this event
        if ($button.hasClass("SaveButton--saved")) {
          $(".Event-attending")
            .next(".LinkedProfiles")
            .append(
              `<li class="LinkedProfiles-profileItem LinkedProfiles-profileItem--isUser"><a class="Avatar" style="background-image: url(${
                window.patchwork.profileImageUrl
              });" href="${window.patchwork.profileLink}"></a></li>`
            );
        } else {
          // Otherwise delete the user's avatar from LinkedProfiles
          $(".LinkedProfiles-profileItem--isUser").remove();
        }

        // If, in updating the LinkedProfiles, we've removed the last item, hide the header
        if ($(".LinkedProfiles > li").length == 0) {
          $(".Event-attending").removeClass("Event-attending--active");
        } else {
          $(".Event-attending").addClass("Event-attending--active");
        }
      })
      .catch(error => console.log(error.response)); // eslint-disable-line no-console
  });
}
