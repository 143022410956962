const $ = window.$;

export default function Muut() {
  const $muut = $(".muut");
  const muut = window.muut;

  if ($muut.length) {
    if (typeof window.muutConfig !== typeof undefined) {
      muut(function() {
        setTimeout(() => {
          $(".campus-channels p a").each((index, item) => {
            if ($(item).hasClass("m-selected")) {
              window
                .$(item)
                .closest(".campus-channels")
                .addClass("expanded");
            }
          });
        }, 500);
      });

      $muut.muut(window.muutConfig);

      $(".muut").on("click", ".school-channel-heading a", () => {
        $(".campus-channels").removeClass("expanded");
      });

      $(".muut").on("click", ".campus-channel-heading a", e => {
        window
          .$(e.target)
          .closest(".campus-channel-heading")
          .next()
          .toggleClass("expanded");
      });

      $(".muut").on("click", ".m-facelink", function(e) {
        const username = $(e.target).attr("title");
        if (typeof username !== typeof undefined) {
          window.location = `/accounts/profile/${username}/`;
        }
      });
    }
  }
}
