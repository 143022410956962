import PropTypes from "prop-types";
import React from "react";

const Section = props => {
  return (
    <div className="Section">
      <div className="Heading">
        <h2 className="Heading-title">{props.title}</h2>
        {props.body && <div className="Heading-subtitle">{props.body}</div>}
      </div>
      {props.children}
    </div>
  );
};

Section.propTypes = {
  body: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string
};

export default Section;
