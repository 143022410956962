import PropTypes from "prop-types";
import React from "react";

function Toggle(props) {
  return (
    <div className="Toggle">
      <div className="Toggle-inner">
        <h3 className="Toggle-label">{props.label}</h3>
        <div className="Toggle-buttons">
          <div
            className="Toggle-indicator"
            style={{
              transform: `translate3D(${props.value ? "0" : "100%"}, 0, 0)`
            }}
          />
          <button
            className="Toggle-button"
            data-test-hook="toggle-on"
            onClick={() => {
              props.handleChange(true);
            }}
            disabled={props.updating}
            style={{
              color: props.value ? "#ffffff" : "inherit"
            }}
          >
            {props.value_true}
          </button>
          <button
            className="Toggle-button"
            onClick={() => {
              props.handleChange(false);
            }}
            disabled={props.updating}
            style={{
              color: !props.value ? "#ffffff" : "inherit"
            }}
          >
            {props.value_false}
          </button>
        </div>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  updating: PropTypes.bool.isRequired,
  value: PropTypes.bool.isRequired,
  value_true: PropTypes.string.isRequired,
  value_false: PropTypes.string.isRequired
};

export default Toggle;
