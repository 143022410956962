const $ = window.$;

export default function Hero() {
  // const $submitButtons = $("button[type='submit']");
  // if ($submitButtons.length) {
  //   $submitButtons.on("click", function(e) {
  //     $(this).addClass("Button--disabled");
  //     $(this).attr("disabled", true);
  //     this.form.submit();
  //   });
  // }
}
