import BookingDates from "../components/BookingDates";
import BookingDetails from "../components/BookingDetails";
import PropTypes from "prop-types";
import React from "react";
import RoomLink from "../components/RoomLink";

function BookingsList(props) {
  return (
    <div className="BookingsList">
      {props.bookings.map((booking) => {
        return (
          <div className="BookingList-item" key={booking.id}>
            <RoomLink
              url={booking.room.url}
              headerImage={booking.room.header_image}
            />
            <div className="BookingList-status">
              <h3 className="BookingList-statusHeading">
                {booking.room.property_name} - {booking.room.title}
              </h3>
              <BookingDates
                startDate={booking.start_date}
                endDate={booking.end_date}
              />
              <BookingDetails
                id={booking.id}
                patchworkerName={props.patchworkerFullName}
                copyOfId={props.patchworkerCopyOfId}
                enrolementLetter={booking.enrolement_letter}
                rentalContract={booking.rental_contract}
                depositReceived={booking.deposit_received}
                notes={booking.notes}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

BookingsList.propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired,
      patchworker: PropTypes.object,
      enrolement_letter: PropTypes.string,
      rental_contract: PropTypes.string,
      deposit_received: PropTypes.bool,
      notes: PropTypes.string,
    })
  ),
  patchworkerFullName: PropTypes.string.isRequired,
  patchworkerCopyOfId: PropTypes.string,
};

export default BookingsList;
