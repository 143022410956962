import scrollMonitor from "scrollmonitor";

// Solution from http://jsbin.com/quhujowota/1/edit?html,js,output

export default function Rating() {
  const ratings = document.querySelectorAll("[data-js='rating']");
  if (!ratings) return;

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(" ");

    return d;
  }

  ratings.forEach(function(el) {
    const shape = el.querySelector("[data-js='rating-shape']");
    const score = el.dataset.score;
    let elementWatcher = scrollMonitor.create(el);
    const endAngle = (359.999 / 100) * (score * 10);

    shape.setAttribute("d", describeArc(60, 60, 56, 0, endAngle));

    // Animate Rating SVG when it is in viewport, & remove watcher
    elementWatcher.enterViewport(function() {
      // animate();
      elementWatcher.destroy();
    });

    // function animate() {
    //   setTimeout(function() {
    //     shape.setAttribute("d", describeArc(60, 60, 56, 0, endAngle));
    //   }, i * 250);
    // }
  });
}
