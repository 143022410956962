import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import React, { useState } from "react";

import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";
import moment from "moment";

function DatePicker(props) {
  const [focusedInput, setFocusedInput] = useState(null);

  function checkIfDateBlocked(moment) {
    if (props.bookedDates.includes(moment.toDate().setHours(0, 0, 0, 0))) {
      return true;
    } else {
      return false;
    }
  }

  let initialVisibleMonth = () => moment();

  if (window.patchwork.ENV === "test") {
    initialVisibleMonth = () =>
      moment(new Date(new Date().getFullYear() + 1, 0, 1));
  }

  return (
    <div className="DatePicker">
      <DateRangePicker
        startDateId="start_date"
        endDateId="end_date"
        onDatesChange={props.setDates}
        onFocusChange={focusedInput => {
          setFocusedInput(focusedInput);
        }}
        initialVisibleMonth={initialVisibleMonth}
        isDayBlocked={checkIfDateBlocked}
        focusedInput={focusedInput}
        startDate={props.startDate}
        endDate={props.endDate}
        showClearDates={props.showClearDates}
        numberOfMonths={1}
      />
    </div>
  );
}

DatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDates: PropTypes.func.isRequired,
  bookedDates: PropTypes.arrayOf(PropTypes.number),
  showClearDates: PropTypes.bool
};

export default DatePicker;
