import PropTypes from "prop-types";
import React from "react";

function DeleteBooking(props) {
  return (
    <div className="DeleteBooking">
      <p className="DeleteBooking-text">
        Are you sure you want to delete this booking? This cannot be undone.
      </p>
      <div className="DeleteBooking-buttons">
        <div className="DeleteBooking-buttonContainer">
          <button className="Button" onClick={props.handleClose}>
            Back
          </button>
        </div>
        <div className="DeleteBooking-buttonContainer">
          <button
            className="Button Button--delete"
            onClick={() => props.handleDeleteBooking(props.id)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteBooking.propTypes = {
  handleDeleteBooking: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default DeleteBooking;
