import React, { useState } from "react";

import PropTypes from "prop-types";
import axios from "axios";

function S3Input(props) {
  const [uploading, setUploading] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <div className={`S3Input ${uploading ? "disabled" : ""}`}>
      <div className="S3Input-buttons">
        <div className="S3Input-input">
          <label className="S3Input-label">
            {uploading
              ? "Uploading..."
              : props.value === ""
              ? "Choose file"
              : "Replace"}
          </label>
          <input
            type="file"
            id={props.name}
            name={props.name}
            disabled={uploading}
            onChange={(e) => {
              setUploading(true);
              setHasError(false);
              const file = e.target.files[0];
              let fileData = new FormData();
              fileData.append("type", file.type);
              fileData.append("name", file.name);
              fileData.append("dest", props.dest);
              const url = "/s3upload/get_upload_params/";
              axios
                .post(url, fileData)
                .then((response) => {
                  const awsPayload = response.data.aws_payload;
                  const key = awsPayload["key"];
                  let awsPayloadData = new FormData();
                  awsPayloadData.append("policy", awsPayload["policy"]);
                  awsPayloadData.append(
                    "success_action_status",
                    awsPayload["success_action_status"]
                  );
                  awsPayloadData.append(
                    "x-amz-credential",
                    awsPayload["x-amz-credential"]
                  );
                  awsPayloadData.append("x-amz-date", awsPayload["x-amz-date"]);
                  awsPayloadData.append(
                    "x-amz-signature",
                    awsPayload["x-amz-signature"]
                  );
                  awsPayloadData.append(
                    "x-amz-algorithm",
                    awsPayload["x-amz-algorithm"]
                  );
                  awsPayloadData.append("key", key);
                  awsPayloadData.append("acl", awsPayload["acl"]);
                  awsPayloadData.append(
                    "content-type",
                    awsPayload["content-type"]
                  );
                  awsPayloadData.append("file", file);
                  axios
                    .post(window.patchwork.mediaUrl, awsPayloadData)
                    .then((response) => {
                      setUploading(false);
                      if (response.status === 201) {
                        props.handleChange(key);
                      } else {
                        setHasError(true);
                        console.log(
                          `Something went wrong: ${response.status} ${response.statusText}`
                        );
                      }
                    })
                    .catch((error) => {
                      setUploading(false);
                      setHasError(true);
                      console.log(error.response);
                    });
                })
                .catch((error) => {
                  setUploading(false);
                  setHasError(true);
                  console.log(error.response);
                });
            }}
          />
        </div>
        {props.value !== "" && (
          <button
            className="S3Input-clear"
            onClick={() => {
              props.handleChange("");
            }}
          >
            Clear
          </button>
        )}
      </div>
      <p className={`S3Input-error${hasError ? " S3Input-error--active" : ""}`}>
        Something went wrong
      </p>
    </div>
  );
}

S3Input.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  dest: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default S3Input;
