const $ = window.$;

export default function Messaging() {
  const muut = window.muut;
  const $messaging = $("[data-js='private-message']");
  const $messagingInit = $("[data-js='messaging-init']");

  if ($messaging.length || $messagingInit.length) {
    if (typeof window.muutConfig !== typeof undefined) {
      muut.messaging(window.muutConfig, function(api) {
        $messaging.on("click", e => {
          api.launch();
          const username = $(e.target).data("username");
          const forum = $(e.target).data("muut-forum-name");
          setTimeout(() => {
            const startName = `${username} <${forum}:${username}>`;
            api.start(startName);
          }, 500);
        });
      });
    }
  }
}
