import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import queryString from "query-string";

export default function DateRange() {
  const dateRangeRoots = document.getElementsByClassName("date-range-root");

  for (var i = 0; i < dateRangeRoots.length; i++) {
    const dateRangeRoot = dateRangeRoots[i];
    const mountPoint = document.createElement("div");
    dateRangeRoot.parentNode.insertBefore(
      mountPoint,
      dateRangeRoot.nextSibling
    );

    const parsedQueryString = queryString.parse(window.location.search);
    let startDate = null;
    let endDate = null;

    if (
      typeof parsedQueryString["start_date"] !== "undefined" &&
      parsedQueryString["start_date"] !== ""
    ) {
      startDate = moment(parsedQueryString["start_date"]);
    }

    if (
      typeof parsedQueryString["end_date"] !== "undefined" &&
      parsedQueryString["end_date"] !== ""
    ) {
      endDate = moment(parsedQueryString["end_date"]);
    }

    updateTextInput(startDate, endDate, dateRangeRoot);

    ReactDOM.render(
      <DateRangeWidget
        dateRangeRoot={dateRangeRoot}
        startDate={startDate}
        endDate={endDate}
      />,
      mountPoint
    );
  }
}

function updateTextInput(startDate, endDate, inputEl) {
  const processedStartDate = startDate ? new Date(startDate) : null;
  const processedEndDate = endDate ? new Date(endDate) : null;
  inputEl.value = JSON.stringify({
    startDate: processedStartDate,
    endDate: processedEndDate
  });
}

class DateRangeWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: props.startDate,
      endDate: props.endDate
    };

    this.handleDatesChanged = this.handleDatesChanged.bind(this);
    this.checkIfDateBlocked = this.checkIfDateBlocked.bind(this);
  }

  handleDatesChanged(startDate, endDate) {
    this.setState({ startDate: startDate, endDate: endDate }, () => {
      updateTextInput(
        this.state.startDate,
        this.state.endDate,
        this.props.dateRangeRoot
      );
    });
  }

  checkIfDateBlocked(moment) {
    const bookedDates = window.patchwork.booked_dates.map(dateString => {
      return new Date(dateString).setHours(0, 0, 0, 0);
    });

    if (bookedDates.includes(moment.toDate().setHours(0, 0, 0, 0))) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <DateRangePicker
        startDate={this.state.startDate}
        startDateId="start_date"
        endDate={this.state.endDate}
        endDateId="end_date"
        onDatesChange={({ startDate, endDate }) => {
          this.handleDatesChanged(startDate, endDate);
        }}
        isDayBlocked={this.checkIfDateBlocked}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        showClearDates
        numberOfMonths={1}
      />
    );
  }
}

DateRangeWidget.propTypes = {
  dateRangeRoot: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  startDate: PropTypes.object
};
