import React, { Component } from "react";

import Input from "./Input";
import PropTypes from "prop-types";
import Tag from "./Tag";

class TagSection extends Component {
  constructor(props) {
    super(props);

    this.renderTags = this.renderTags.bind(this);
  }

  renderTags() {
    return this.props.tags.map(tag => {
      return (
        <Tag
          key={tag}
          handleDelete={tagToDelete => {
            this.props.handleRemoveTag(tagToDelete);
          }}
          tagName={tag}
        />
      );
    });
  }

  render() {
    return (
      <div className="TagSection">
        <Input
          actionType="add"
          attr={this.props.attr}
          handleAction={this.props.handleAddTag}
          initialValue=""
          label={this.props.label}
          placeholder={this.props.placeholder}
        />
        <div className="TagList">{this.renderTags()}</div>
      </div>
    );
  }
}

TagSection.propTypes = {
  attr: PropTypes.string.isRequired,
  handleAddTag: PropTypes.func.isRequired,
  handleRemoveTag: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TagSection;
