import Cookies from "js-cookie";
import LandlordApp from "./LandlordApp";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

window.addEventListener("load", () => {
  const csrfToken = Cookies.get("csrftoken");
  axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
  axios.defaults.headers.patch["X-CSRFToken"] = csrfToken;
  axios.defaults.headers.delete["X-CSRFToken"] = csrfToken;

  const root = document.getElementById("root");
  if (!root) return;

  ReactDOM.render(<LandlordApp />, root);
});
