import "./components/muut";
import "./components/get-programmes";
import "./edit-profile/index";
import "./landlord-dashboard/index";

import * as Sentry from "@sentry/browser";

import CSSHacks from "./components/css-hacks";
import DateRange from "./components/date-range-widget";
import DateSearch from "./components/date-search";
import Dropdown from "./components/dropdown";
import DynamicBackButton from "./components/dynamic-back-button";
import FilterEvents from "./components/filter-events";
import FormSubmitDebounce from "./components/form-submit-debounce";
import Gallery from "./components/gallery";
import Hero from "./components/hero";
import LoadArticles from "./components/load-articles";
import Messaging from "./components/messaging";
import Modal from "./components/modal";
import Muut from "./components/muut";
import Rating from "./components/rating";
import ResponsiveBackgroundImages from "./components/responsive-background-images";
import RoomAvailability from "./components/room-availability";
import Rules from "./components/rules";
import SaveEvent from "./components/save-event";
import SlideNav from "./components/slide-nav";

console.log(`Patchwork ${patchwork.VERSION}`);

if (process.env.NODE_ENV !== "development") {
  try {
    Sentry.init({
      dsn: window.bridge.SENTRY_DSN,
      release: window.bridge.VERSION
    });
  } catch (error) {
    console.log("Error setting up Sentry");
  }
}

CSSHacks();
DateRange();
DateSearch();
Dropdown();
DynamicBackButton();
FilterEvents();
FormSubmitDebounce();
Gallery();
Hero();
LoadArticles();
Messaging();
Modal();
Muut();
Rating();
ResponsiveBackgroundImages();
RoomAvailability();
Rules();
SaveEvent();
SlideNav();
