export default function Modal() {
  const body = document.querySelector("[data-js='body']");
  const modal = document.querySelector("[data-js='modal']");
  if (!modal) return;
  const trigger = document.querySelectorAll("[data-js='modal-trigger']");
  const content = modal.querySelector("[data-js='modal-content']");

  function toggleModal() {
    body.classList.toggle("hasModal");
    modal.classList.toggle("isActive");
    content.classList.toggle("isActive");
  }

  // Open modal when clicking "add" button
  trigger.forEach(function(el) {
    el.addEventListener("click", function() {
      toggleModal();
    });
  });
}
