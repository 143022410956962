import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import React, { PureComponent } from "react";

import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import queryString from "query-string";

export default function DateSearch() {
  const dateSearchRoots = document.getElementsByClassName("DateSearch-root");

  const parsedQueryString = queryString.parse(window.location.search);
  let startDate = null;
  let endDate = null;

  if (typeof parsedQueryString["start_date"] !== "undefined") {
    startDate = parsedQueryString["start_date"];
  }

  if (typeof parsedQueryString["end_date"] !== "undefined") {
    endDate = parsedQueryString["end_date"];
  }

  for (let dateSearchRoot of Array.from(dateSearchRoots)) {
    ReactDOM.render(
      <DateSearchWidget startDate={startDate} endDate={endDate} />,
      dateSearchRoot
    );
  }
}

class DateSearchWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      startDate: props.startDate ? moment(props.startDate) : null,
      endDate: props.startDate ? moment(props.endDate) : null,
      showWarning: false
    };

    this.handleDatesChanged = this.handleDatesChanged.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleDatesChanged({ startDate, endDate }) {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      showWarning: false
    });
  }

  handleClick() {
    if (this.state.startDate && this.state.endDate) {
      const startDate = this.state.startDate.format("YYYY-MM-DD");
      const endDate = this.state.endDate.format("YYYY-MM-DD");
      window.location.search = `?start_date=${startDate}&end_date=${endDate}`;
    } else {
      this.setState({ showWarning: true });
    }
  }

  render() {
    return (
      <div className="DateSearch">
        <div className="DateSearch-inner">
          <DateRangePicker
            startDateId="start_date"
            endDateId="end_date"
            onDatesChange={this.handleDatesChanged}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            focusedInput={this.state.focusedInput}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            showClearDates
            numberOfMonths={1}
          />
          <div className="DateSearch-button">
            <button className="Button" onClick={this.handleClick}>
              Search
            </button>
          </div>
        </div>
        <p
          className={`DateSearch-warning${
            this.state.showWarning ? " DateSearch-warning--active" : ""
          }`}
        >
          Please enter both a start and a finish date
        </p>
      </div>
    );
  }
}

DateSearchWidget.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string
};
