import PropTypes from "prop-types";
import React from "react";

const Select = props => {
  return (
    <div>
      {props.label && (
        <label className="Form-label Add-label" htmlFor={props.attr}>
          {props.label}
        </label>
      )}

      <div
        className={`Select${
          props.extraClassNames ? " " + props.extraClassNames : ""
        }`}
      >
        <span className="Select-arrow" />
        <select
          defaultValue={props.defaultValue}
          name={props.attr}
          onChange={event => {
            props.onChange({ [props.attr]: event.target.value });
          }}
        >
          <option value="">{props.emptyText}</option>
          {props.choices.map(choice => {
            const type = Object.keys(choice)[0];
            return (
              <option key={type} value={type}>
                {choice[type]}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  attr: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.string,
  emptyText: PropTypes.string.isRequired,
  extraClassNames: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default Select;
