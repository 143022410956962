const $ = window.$;

export default function DynamicBackButton() {
  const $backButtons = $("[data-js='back-button']");
  if ($backButtons.length == 0) return;

  $backButtons.each((index, el) => {
    const $backButton = $(el);
    $backButton.click(e => {
      e.preventDefault();
      const referrer = document.referrer;
      if (
        referrer.indexOf("patchwork") != -1 ||
        referrer.indexOf("localhost") != -1
      ) {
        window.history.back();
      } else {
        window.location.href = $backButton.attr("href");
      }
    });
  });
}
